import React from 'react';
import List from '../../../components/list/List';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ActionPartial,
  ClassnamePartial,
  EnvironmentPartial,
  OnDismissPartial,
  QaIdPartial,
  CodeSnippet,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const ActionBar = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.ActionBar;
    return <Component {...props} />;
  },
});

const IconShare = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconShare;
    return <Component {...props} />;
  },
});

const IconDelete = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconDelete;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../data/snippets/action-bar.example');

const WebTab = () => {
  return (
    <PlatformTab platform="web">
      <V5Notice
        code
        componentName="Action Bar"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-action-bar--docs"
      />
      <CodeSnippet
        scope={{
          React: React,
          ActionBar: ActionBar,
          IconShare: IconShare,
          IconDelete: IconDelete,
        }}
        code={snippet}
        platform="react"
        gitHubLink="action-bar"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ActionPartial
            showText
            showIcon
            showStatus
            showDisabled
            showTooltip
            showQaid
            showOnClick
          />

          <ClassnamePartial componentName="action bar" />

          <EnvironmentPartial />

          <PropListItem name="isOpen" types={['boolean']} isRequired>
            <Text>Determines whether the action bar is visible.</Text>
          </PropListItem>

          <PropListItem name="text" types={['string']} isRequired>
            <Text>The text that appears on the left side.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onAfterOpen" types={['() => void']}>
            <Text>Called after the action bar is opened.</Text>
          </PropListItem>

          <OnDismissPartial componentName="action bar" />
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial
            componentName="action bar"
            passedDown={['text', 'dismiss']}
          />
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            The action bar is positioned via <code>sticky</code>. That means it
            will take up space in the flow of the document until the user
            scrolls down, at which point it will become fixed. If you don't want
            the empty space taken up by the action bar, you'll need to give your
            container a negative margin.
          </li>
          <li>
            The actions have a <code>className</code> prop in case you need to
            hide the label or icon at smaller sizes.
          </li>
          <li>
            In order for the tooltips to appear, you'll need to pass a ref to
            your Tooltip component and then call{' '}
            <code>tooltipRef.rebuild()</code> since the action bar is
            dynamically generated.
          </li>
        </List>
      </Section>
      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Action Bar"
      />
    </PlatformTab>
  );
};

export default WebTab;
